import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import JobSearch from "@sourceflow-uk/job-search";
import {
    Container,
    Row,
    Col,
    Card
} from 'reactstrap';
import styles from './styles.module.scss';
import Link from "next/link"
import React, {
    useRef,
    useEffect,
    useState
} from "react";
import Arrow from "/public/site-assets/svg/black-arrow.svg"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper"

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

export default function LatestJobs({
    global,
    pathPrefix,
    customClass = "",
    content,
    buttonTitle,
    buttonLink,
    post,
    swiperID,
    jobSearch = false,
    locale = "https://www.lorienglobal.com/"
}) {
    const settings = {
        modules: [Navigation, Pagination, Autoplay],
        loop: false,
        spaceBetween: 30,
        slidesPerView: 1,
        speed: 500,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            clickable: true,
            el: `.swiper-custom-paginations .${swiperID}`,
        },
        navigation: {
            nextEl: `.${swiperID}.swiper-next`,
            prevEl: `.${swiperID}.swiper-prev`
        },
        breakpoints: {
            1200: {
                slidesPerView: 4,
            },
            991: {
                slidesPerView: 3,
            },
            767: {
                slidesPerView: 2,
            },
        }
    }
    return (
        post?.length >= 1 && (
            <section className={`${styles.root} ${customClass} position-relative`} id="latest-jobs">
                <Container>
                    <Row className="mb-4">
                        <Col md={6}>
                            {content ?
                                <SourceFlowText global={global} path={`${pathPrefix}.content`} type="html">
                                    {content}
                                </SourceFlowText>
                                : null}
                        </Col>
                        <Col md={6} className="d-flex justify-content-md-end align-items-center">
                            {jobSearch ?
                                null :
                                <div className="d-flex swiperArrowWrapper me-4">
                                    <button className={`${swiperID} arrow swiper-prev border-0 p-0`} aria-label="Prev"><Arrow /></button>
                                    <button className={`${swiperID} arrow swiper-next border-0 ms-2 p-0`} aria-label="Next"><Arrow /></button>
                                </div>}
                            {buttonLink ?
                                <div className="text-center">
                                    <Link href={`${buttonLink}`}>
                                        <a className="primaryBtn no-arrow m-0">
                                            {buttonTitle}
                                        </a>
                                    </Link>
                                </div> : null}
                            {jobSearch ?
                                <div className="w-100 mt-2 mt-md-0">
                                    <JobSearch
                                        options={{
                                            searchResults: false,
                                            searchFilters: false,
                                            submitDestination: `${locale}jobs`,
                                        }}
                                        translations={{
                                            "input.placeHolder": "Job Title or Keyword",
                                            "button.text": "Search all jobs",
                                        }}
                                    />
                                </div> : null}
                        </Col>
                    </Row>
                    {jobSearch ?
                        <div className="d-flex swiperArrowWrapper justify-content-end mb-4">
                            <button className={`${swiperID} arrow swiper-prev border-0 p-0`} aria-label="Prev"><Arrow /></button>
                            <button className={`${swiperID} arrow swiper-next border-0 ms-2 p-0`} aria-label="Next"><Arrow /></button>
                        </div> : null}

                    <Swiper {...settings}>
                        {post?.map((post, index) =>
                            <SwiperSlide key={index}>
                                <Link href={`${locale ? locale : ""}/jobs/${post?.url_slug}`}>
                                    <a className="text-decoration-none">
                                        <Card className="p-3 rounded-4 h-100">
                                            <div className="post-sector">
                                                {post?.categories.map((cat) => cat.id == "90291455-4c9a-428f-b32b-87e4523fd608" && cat.values?.map((value) => value.name ? <>
                                                    <span>
                                                        {value?.name}
                                                    </span >
                                                </>
                                                    : null))}
                                            </div>
                                            <p className="fs-5 post-title mt-2 mb-4">{post.title}</p>
                                            {post?.location ?
                                                <p className="post-location mb-0">
                                                    {post?.location}
                                                </p>
                                                : null}
                                            {post.categories.map((cat) => cat.id == "90f0c208-6dd9-4e68-accd-7e637ade733f" && cat.values?.map((value) => value.name ? <>
                                                <p className="post-type mb-0">
                                                    {value?.name}
                                                </p>
                                            </> : ""))}
                                            {post?.salary_package ?
                                                <p className="post-salary mt-3">
                                                    {post?.salary_package === "Up to US$0.00 per hour" ? "Competitive" : post?.salary_package}
                                                </p>
                                                : null}
                                        </Card >
                                    </a>
                                </Link>
                            </SwiperSlide>
                        )}
                    </Swiper>
                    <div className="text-center swiper-custom-paginations mt-4">
                        <div className={`${swiperID}`} />
                    </div>
                </Container>
            </section >
        )
    )
}